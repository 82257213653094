<template>
	<div class="sankey">
		<div class="data">
			Total: {{ allVotes }} |
			Von anderen: {{ gainedVotes }} ({{ gainedPercentage }}%) |
			Zu anderen: {{ lostVotes }} ({{ lostPercentage }}%) |
			Netto: {{ (gainedPercentage -  lostPercentage).toFixed(2) }}%
		</div>
		<div id="sankey"></div>
	</div>
</template>
<script>
import * as d3 from 'd3v4';
import {
	sankeyLinkHorizontal,
	sankey as sankeyGraph
} from '@monfera/d3-sankey';
import Full2020 from '../data/2023_nr.json';

export default {
	props: ['party'],
	methods: {
		async parseData() {

			let foundParties = [];
			let partyObject = this.getPartyObject();

			this.rawData.filter(set => {

				if (!foundParties.some(party => party.code === set.parteikurzbezeichnung)) {
					foundParties.push({
						'code': set.parteikurzbezeichnung,
						'name': set.parteibezeichnung,
						'hlv_nr': set.hlv_nr,
						'ulv_nr': set.ulv_nr
						});
					return true;
				}

				return false;
			});

			let spIndex;
			this.partyList.forEach(function (value, index) {
				let name = value.code
				if (name == this.party) spIndex = index;

				this.data.nodes.push({
					node: index,
					name: name,
					partyColor: this.colors[name],
					partyCode: this.partyCodes[name]
				});
			}.bind(this));

			this.partyList.forEach(function (value, index) {
				let name = value.code
				
				this.data.nodes.push({
					node: index,
					name: name,
					partyColor: this.colors[name],
					partyCode: this.partyCodes[name]
				});
			}.bind(this));

			const selectedPartyObject = this.partyList.find(party => party.code === this.party);
			let spdata = this.rawData.filter(set => {
				if(selectedPartyObject.ulv_nr && selectedPartyObject.ulv_nr == set.ulv_nr) {
					return true;
				} else if (set.parteikurzbezeichnung == this.party) {
					return true;
				} 
				
				return false
			});

			this.allVotes = 0;
			spdata.forEach(function (value, index) {
				if(value.wahlkreis_code == 1) { 

				this.allVotes += value.stimmen_total_aus_wahlzettel;

				foundParties.forEach(function (party, index) {

					let num = value[this.partyCodes[party.code]];
					if(!this.partyCodes[party.code]) {
						console.log(party.code);
					}
					let addLink = false;
 
					if(party.ulv_nr) {
						if(party.ulv_nr !== selectedPartyObject.ulv_nr){
							addLink = true;
						}
					} else {
						if(party.code !== selectedPartyObject.code) {
							addLink = true;
						}
					}

					if (addLink) {
						this.gainedVotes += num;
						let name = party.code;

						if(party.ulv_nr) {
							let partyThing = this.partyList.find(partyListObj => partyListObj.ulv_nr === party.ulv_nr);
							name = partyThing.code;
						}

						let source = this.data.nodes.filter(node => {
							return node.name === name
						});

						this.data.links.push({
							source: source[0].node,
							target: spIndex,
							value: num
						});
						
					}
				}.bind(this))
				}

			}.bind(this));
			
			let noneSp = this.rawData.filter(set => {

				if(selectedPartyObject.ulv_nr) {
					if(selectedPartyObject.ulv_nr == set.ulv_nr) {
						return false;
					} else {
					return true;
					}
				} else if (set.parteikurzbezeichnung !== this.party) {
					return true;
				} 
				
				return false;
				
			});

			let test = 0;
			
			noneSp.forEach(function (value, index) {
								if(value.wahlkreis_code == 1) { 

				foundParties.forEach(async function (party, index) {

					let num = value[this.partyCodes[party.code]];
					let addLink = false;
 
					if(party.ulv_nr) {
						if(party.ulv_nr == selectedPartyObject.ulv_nr){
							addLink = true;
						}
					} else {
						if(party.code == selectedPartyObject.code) {
							addLink = true;
						}
					}
					if (addLink && num) {
						this.lostVotes += num;
						let name = value.parteikurzbezeichnung;

						if(value.ulv_nr) {
							let partyThing = this.partyList.find(partyListObj => partyListObj.ulv_nr === value.ulv_nr);
							name = partyThing.code;
						}

						let target = this.data.nodes.filter(node => {
							return node.name === name
						});


						this.data.links.push({
							source: spIndex,
							target: target[0].node + this.partyList.length,
							value: num
						});
						
					}
				}.bind(this))
								}
			}.bind(this));
			

			this.gainedPercentage = ((this.gainedVotes / this.allVotes) * 100).toFixed(2);
			this.lostPercentage = ((this.lostVotes / this.allVotes) * 100).toFixed(2);
		},
		getPartyObject() {
			return this.partyList.filter(party => {
				return party.code == this.party
			})[0];
		}
	},
	data() {
		return {
			allVotes: 0,
			lostVotes: 0,
			gainedVotes: 0,
			gainedPercentage: 0,
			lostPercentage: 0,
			rawData: Full2020,
			data: {
				nodes: [],
				links: []
			},
			colors: {
				Ohne: '#00cc33',
				GP: '#00cc33',
				BGB: '#E7007E',
				SP: '#fa0304',
				MITTE: '#fd9900',
				GLP: '#cbd662',
				LDP: '#343398',
				FDP: '#0267f7',
				SVP: '#026201',
				EVP: '#ffff06',
				PdA: '#E51816'
			},
			partyCodes: {
				Ohne: "00_ohne",
				GP: '17_gp',
				BastA: '27_basta',
				SP: '05_sp',
				Mitte: '07_mitte',
				GLP: '10_glp',
				LDP: '03_ldp',
				FDP: '01_fdp',
				SVP: '12_svp',
				EVP: '04_evp',
				Unab: '30_unabh',
				JUSO: '20_juso',
				Mitte60PLUS: '36_mitte60',
				jgb: '21_jgb',
				JFDP: '06_jfdp',
				GLPI: '38_glp_i',
				GLPKMU: '37_glp_kmu',
				GLPRE: '35_glp_r_e',
				LDPGew: '34_ldp_gew',
				GLPB: '33_glp_b',
				GLPKU: '32_glp_k_u',
				SVP60PLUS: '31_svp60',
				SVPGew: '30_svp_gew',
				MV: '28_mv',
				BastAjA: '27_basta_ja',
				FDPPLUS: '26_fdp',
				JGLP: '25_jglp',
				VA: '14_va',
				BGB: '08_bgb',
				EDU: '09_edu',
				PdA: '11_pda',
				JSVP: '12_svp',
				JMITTE: '07_mitte',
				JLB: '23_jlb',
				SP60PLUS: '24_sp60',
				Bastarm: '17_basta_rm'
			},
			partyList: [
				{
					name: 'Sozialdemokratische Partei',
					code: "SP",
					hlv_nr: "2",
					ulv_nr: "3",
				},
				{
					name: 'Bündnis Grüne, BastA!',
					code: "BGB",
					hlv_nr: "2",
        			ulv_nr: "5",
				},
				{
					name: 'LDP Liberal-Demokratische',
					code: "LDP",
					hlv_nr: "1",
        			ulv_nr: "2",
				},
				{
					name: 'Schweizerische Volkspartei',
					code: "SVP",
        			hlv_nr: "3",
        			ulv_nr: "7",
				},
				{
					name: 'Grünliberale',
					code: "GLP",
					hlv_nr: "1",
        			ulv_nr: "6",
				},
				{
					name: 'FDP.Die Liberalen',
					code: "FDP",
					hlv_nr: "1",
        			ulv_nr: "1"
				},
				{
					name: 'Mitte',
					code: "MITTE",
					hlv_nr: "1",
        			ulv_nr: "4",
				},
				{
					name: 'Evangelische Volkspartei',
					code: "EVP",
					hlv_nr: "1",
        			ulv_nr: null,
				},
				{
				    code: "EDU",
        			sname: "Eidgen\u00f6ssisch Demokratische Union Basel-Stadt (EDU)",
        			hlv_nr: "3",
        			ulv_nr: null,
				},
				{
					code: "PdA",
        			name: "Partei der Arbeit (PdA) Basel",
        			hlv_nr: null,
        			ulv_nr: null,
				},
				{
					code: "VA",
        			name: "Volks Aktion",
        			hlv_nr: null,
        			ulv_nr: null,
				},
				{
					code: "MV",
        			name: "Mass Voll",
        			hlv_nr: null,
        			ulv_nr: null,
				},
			]
		}
	},
	mounted() {
		this.parseData();

		let margin = {top: 0, right: 250, bottom: 200, left: 0};
		let width = window.innerWidth - margin.left - margin.right;
		let height = window.innerHeight - margin.top - margin.bottom;

		var svg = d3.select("#sankey").append("svg")
		.attr("width", width + 100)
		.attr("height", height + 50)
		.append("g")
		.attr("transform",
				"translate(" + margin.left + "," + margin.top + ")");

		var sankey = sankeyGraph()
		.nodeWidth(6)
		.nodePadding(20)
		.size([width, height]);

		sankey
		.nodes(this.data.nodes)
		.links(this.data.links)
		.layout(1);

		var link = svg.append("g")
		.selectAll(".link")
		.data(this.data.links)
		.enter()
		.append("path")
		.attr("class", "link")
		.attr("d", sankey.link())
		.style("stroke-width", function (d) {
			return Math.max(40, d.dy);
		})
		.sort(function (a, b) {
			return b.dy - a.dy;
		});

		var node = svg.append("g")
		.selectAll(".node")
		.data(this.data.nodes)
		.enter().append("g")
		.attr("class", "node")
		.attr("transform", function (d) {
			return "translate(" + d.x + "," + d.y + ")";
		})
		.style("display", function (d) {
			if (d.value == 0) {
				return 'none';
			}
		});

		node
		.append("rect")
		.attr("height", function (d) {
			return d.dy;
		})
		.attr("width", sankey.nodeWidth())
		.style("fill", function (d) {
			return d.partyColor;
		})
		.style("stroke", function (d) {
			return d.partyColor;
		})
		.append("title")
		.text(function (d) {
			return d.name + " " + d.value + " Stimmen";
		});

		node
		.append("text")
		.attr("x", 12)
		.attr("y", function (d) {
			return d.dy / 2;
		})
		.attr("dy", ".35em")
		.attr("text-anchor", "start")
		.attr("transform", null)
		.text(function (d) {
			if (d.name === this.party) return d.name;

			return d.name + " (" + d.value + ")";
		}.bind(this))
		.filter(function (d) {
			return d.x < width / 2;
		})
		.attr("x", 6 + sankey.nodeWidth())
		.attr("text-anchor", "start")
	}
}
</script>
<style>
.link {
	fill: #fa0304;
	stroke: none;
	stroke-opacity: .2;
	opacity: .2;
}

.sankey {
	position: relative;
}
</style>