<template>
	<div id="graph">
		<div class="select">
			<v-select :options="partyList" :reduce="party => party.code"
					label="name" v-model="defaultSelected" :value="party" dense
								solo :clearable="false" @input="changeParty"
			/>
		</div>
		<SankeyNR v-if="renderSankey" :party="party"></SankeyNR>
	</div>
</template>
<script>
import Bars from '@/components/Bars.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SankeyNR from '../components/SankeyNR.vue';

export default {
	name: "Table",
	components: {
		Bars,
		vSelect,
		SankeyNR
	},
	methods: {
		changeParty(party) {
			this.party = party;

			this.renderSankey = false;

			this.$nextTick(() => {
				this.renderSankey = true;
			});
		}
	},
	data() {
		return {
			party: 'SP',
			renderSankey: true,
			defaultSelected: {
				name: 'Sozialdemokratische Partei',
				code: "SP",
				hlv_nr: "2",
				ulv_nr: "3",
			},
			partyList: [
				{
					name: 'Sozialdemokratische Partei',
					code: "SP",
					hlv_nr: "2",
					ulv_nr: "3",
				},
				{
					name: 'Bündnis Grüne, BastA!',
					code: "BGB",
					hlv_nr: "2",
        			ulv_nr: "5",
				},
				{
					name: 'LDP Liberal-Demokratische',
					code: "LDP",
					hlv_nr: "1",
        			ulv_nr: "2",
				},
				{
					name: 'Schweizerische Volkspartei',
					code: "SVP",
        			hlv_nr: "3",
        			ulv_nr: "7",
				},
				{
					name: 'Grünliberale',
					code: "GLP",
					hlv_nr: "1",
        			ulv_nr: "6",
				},
				{
					name: 'FDP.Die Liberalen',
					code: "FDP",
					hlv_nr: "1",
        			ulv_nr: "1"
				},
				{
					name: 'Mitte',
					code: "MITTE",
					hlv_nr: "1",
        			ulv_nr: "4",
				},
				{
					name: 'Evangelische Volkspartei',
					code: "EVP",
					hlv_nr: "1",
        			ulv_nr: null,
				},
				{
				    code: "EDU",
        			sname: "Eidgen\u00f6ssisch Demokratische Union Basel-Stadt (EDU)",
        			hlv_nr: "3",
        			ulv_nr: null,
				},
				{
					code: "PdA",
        			name: "Partei der Arbeit (PdA) Basel",
        			hlv_nr: null,
        			ulv_nr: null,
				},
			]
		}
	}
}
</script>
<style scoped>

#graph {
	margin-top: 50px;
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.select {
	margin-top: 50px;
	width: 100%;
	margin-bottom: 15px;
}
.v-select {
	background: white !important;
	width: 50%;
}

</style>